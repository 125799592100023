















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScheduledReportsPopup from '@/modules/scheduled-reports';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/scheduled-reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient,
} from '@/modules/scheduled-reports/interfaces';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import loop24 from '@/modules/common/filters/loop-24.filter';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import DILiteService, { DiLiteAllChannelsServiceS } from '../di-lite-all-channels.service';
import { DILiteFilterDevice } from '../models/all-channels-settings.model';

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @Inject(DiLiteAllChannelsServiceS) private diliteService!: DILiteService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;

    readonly dataType = DATA_TYPE.DI_LITE;

    get properties(): IProperties {
        return {
            dataType: this.dataType,
            dateRange: {
                options: [30, 60, 90],
                value: 30,
            },
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns() {
        return [];
    }

    get filters(): IFilterItem[] {
        const filters = [] as IFilterItem[];

        if (this.diliteService.isLoading) {
            return filters;
        }

        if (this.compsetsService.currentCompset) {
            const options = this.compsetsService.compsets!
                .map(compset => ({
                    name: compset.name,
                    value: compset.id,
                }));
            const value = this.compsetsService.currentCompset.id;

            filters.push({
                name: 'compset',
                label: 'Comp Set',
                value,
                options,
                disableOnEdit: true,
            });
        }

        if (this.diliteService.filters.pos) {
            filters.push({
                name: 'pos',
                label: 'POS',
                value: this.diliteService.filters.pos,
                options: this.documentFiltersService.posRatesItems,
                disableOnEdit: false,
            });
        }

        filters.push({
            name: 'los',
            label: 'LOS',
            value: this.diliteService.filters.los || DEFAULT_LOS[0],
            options: DEFAULT_LOS.map(l => ({
                name: String(l),
                value: l,
            })),
            disableOnEdit: false,
        });

        const { mealTypes } = this.mealTypesService;
        if (!mealTypes) {
            return [];
        }

        filters.push({
            name: 'meal_type',
            label: 'Meal Type',
            value: this.diliteService.settings.mealTypeId === ANY_MEAL_TYPE.id
                ? mealTypes.filter(mt => mt.id !== ANY_MEAL_TYPE.id).map(mt => mt.name)
                : [this.mealTypesService.getMealType(this.diliteService.settings.mealTypeId)!.name],
            options: mealTypes
                .filter(mt => mt.id !== ANY_MEAL_TYPE.id)
                .map((mealType: MealTypeModel) => ({
                    value: mealType.name,
                    name: mealType.displayName,
                })),
            disableOnEdit: false,
        });

        const { rooms } = this.roomTypesService;
        if (!rooms) {
            return [];
        }

        filters.push({
            name: 'room_type',
            label: 'Room Type',
            value: this.diliteService.settings.roomTypeId === ANY_ROOM_TYPE.id
                ? rooms.filter(rt => rt.id !== ANY_ROOM_TYPE.id).map(room => room.name)
                : [this.roomTypesService.getRoomType(this.diliteService.settings.roomTypeId)!.name],
            options: rooms
                .filter(rt => rt.id !== ANY_ROOM_TYPE.id)
                .map((room: RoomTypeModel) => ({
                    value: room.name,
                    name: room.name,
                })),
            disableOnEdit: false,
        });

        filters.push({
            name: 'occupancy',
            label: 'Occupancy',
            value: this.diliteService.settings.numberOfGuests,
            options: Array
                .from({ length: 10 })
                .map((_, i) => i + 1)
                .map(e => ({
                    value: e,
                    name: e + (e > 1 ? ' guests' : ' guest'),
                })),
            disableOnEdit: false,
        });

        filters.push({
            name: 'price',
            label: 'Price Type',
            value: PRICE_TYPE.LOWEST,
            options: [
                {
                    name: 'Lowest',
                    value: PRICE_TYPE.LOWEST,
                },
            ],
            disableOnEdit: false,
        });

        let priceTypeValue = 'shown_price';
        switch (this.diliteService.settings.priceShown) {
            case PRICE_SHOWN.TOTAL:
                priceTypeValue = 'total_price';
                break;
            case PRICE_SHOWN.NET:
                priceTypeValue = 'net_price';
                break;
            default:
                priceTypeValue = 'shown_price';
                break;
        }

        filters.push({
            name: 'price_type',
            label: 'Price',
            value: priceTypeValue,
            options: [
                { value: 'shown_price', name: PRICE_SHOWN.SHOWN },
                { value: 'net_price', name: PRICE_SHOWN.NET },
                { value: 'total_price', name: PRICE_SHOWN.TOTAL },
            ],
            disableOnEdit: false,
        });

        filters.push({
            name: 'device_name',
            label: 'Device Name',
            value: this.diliteService.settings.device,
            options: [
                {
                    value: DILiteFilterDevice.ALL,
                    name: 'All',
                },
                {
                    value: DILiteFilterDevice.DESKTOP,
                    name: 'Desktop',
                },
                {
                    value: DILiteFilterDevice.MOBILE,
                    name: 'Mobile app',
                },
            ],
            disableOnEdit: false,
        });

        return filters;
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '0',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }
}
